<template>
  <div>
    <category-list-add-new
      :is-add-new-tag-sidebar-active.sync="isAddNewTagSidebarActive"
      :tag-data="tagData"
      :cat-data="dataCategories"
      @reset-data="resetTagData"
      @refetch-data="getTagData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row class=" justify-content-end">
          <!-- Per Page -->
          <b-col
            v-if="!selectTag"
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getTagData"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="selectTag"
                :options="dataCategories"
                :reduce="val => val.tagging_id"
                style="min-width:120px"
                class="small p-0 mr-50 w-25"
                label="name"
                placeholder="Tag"
                @input="getTagData"
              />
              <b-form-input
                v-model="searchQuery"
                class="w-25 mr-50"
                placeholder="Search..."
                @keyup.enter="getTagData()"
              />
              <b-button
                variant="primary"
                @click="isAddNewTagSidebarActive = true"
              >
                <span class="text-nowrap">Add New Sub Tag</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <template v-if="selectTag">
        <b-row class="text-center font-weight-bold">
          <b-col class="border-bottom pb-1">
            Name
          </b-col>
          <b-col class="border-bottom pb-1">
            Parent
          </b-col>
          <b-col class="border-bottom pb-1">
            Author
          </b-col>
          <b-col class="border-bottom pb-1">
            Last Update
          </b-col>
          <b-col class="border-bottom pb-1">
            Action
          </b-col>
        </b-row>
        <!-- draggable -->
        <draggable
          v-model="tagList"
          tag="ul"
          class="list-group list-group-flush cursor-move border-bottom mb-2"
          @change="submitChange"
        >
          <b-list-group-item
            v-for="(listItem, index) in tagList"
            :key="index"
            tag="li"
          >
            <b-row>
              <b-col>
                <b-card-text class="mb-0 font-weight-bold">
                  {{ listItem.name }}
                </b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="mb-0 font-weight-bold">
                  {{ listItem.parent }}
                </b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="mb-0">
                  <div>
                    {{ listItem.author }}
                    <template v-if="listItem.editor && listItem.editor !== listItem.author">
                      <br><span class="small">Last edit by :</span> {{ listItem.editor }}
                    </template>
                  </div>
                </b-card-text>
              </b-col>
              <b-col class="text-center">
                <b-card-text class="mb-0">
                  {{ listItem.updated_at | formatDate }}
                </b-card-text>
              </b-col>
              <b-col class="text-center">
                <b-card-text class="mb-0"><b-dropdown
                  variant="link"
                  no-caret
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item @click="editData(listItem)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmDel(listItem)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
                </b-card-text>
              </b-col>
            </b-row>
          </b-list-group-item>
        </draggable>
      </template>

      <b-table
        v-if="!selectTag"
        ref="refTagListTable"
        class="position-relative"
        :items="tagList"
        responsive
        :fields="tableColumns"
        primary-key="subtagging_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getTagData"
      >

        <!-- Column: User -->
        <template #cell(title)="data">
          <b-link
            :to="{ name: 'users-view', params: { id: data.item.subtagging_id } }"
            class="font-weight-bold mb-50"
          >
            {{ data.item.title }}
          </b-link>
          <p class="small mb-50">
            {{ data.item.message }}
          </p>
        </template>

        <!-- Column: Updated At -->
        <template #cell(author)="data">
          <div class="text-nowrap">
            {{ data.item.author }}
            <template v-if="data.item.editor && data.item.editor !== data.item.author">
              <br><span class="small">Last edit by :</span> {{ data.item.editor }}
            </template>
          </div>
        </template>

        <!-- Column: Updated At -->
        <template #cell(updated_at)="data">
          <div class="text-nowrap">
            {{ data.item.updated_at | formatDate }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editData(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDel(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div
        v-if="!selectTag"
        class="mx-2 mb-2"
      >
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BDropdown, BDropdownItem, BPagination, BListGroupItem,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CategoryListAddNew from './SubListAddNew.vue'

// Notification

export default {
  components: {
    CategoryListAddNew,

    BCard,
    BCardText,
    BListGroupItem,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    draggable,
  },
  setup() {
    const toast = useToast()
    const isAddNewTagSidebarActive = ref(false)

    // Table Handlers
    const tableColumns = [
      { key: 'name', sortable: true },
      { key: 'parent', sortable: true },
      { key: 'author', sortable: true },
      { key: 'updated_at', label: 'Last Update', sortable: true },
      { key: 'actions' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('subtagging_id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)

    return {
      toast,
      // Sidebar
      isAddNewTagSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Extra Filters
      roleFilter,
    }
  },
  data() {
    return {
      tagList: [],
      dataCategories: [],
      dataMeta: '',
      selectTag: '',
      tagData: {},
    }
  },
  created() {
    this.getTagData()
  },
  methods: {
    submitChange(value) {
      this.update = true
      const params = {
        subtagging_id: value.moved.element.subtagging_id,
        tagging_id: this.selectTag,
        old: value.moved.oldIndex,
        new: value.moved.newIndex,
      }
      this.$http.post('/subtag/order', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Sub Tag Order Updated',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error update Sub Tag Order',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getTagData()
    },
    getTagData() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        length: this.perPage,
      }
      if (this.searchQuery) {
        params.keyword = this.searchQuery
      }
      if (this.selectTag) {
        params.tagging_id = this.selectTag
        params.length = 1000
      }
      if (this.sortBy) {
        params.sort_by = [{
          column: this.sortBy,
          asc_desc: (this.isSortDirDesc) ? 'desc' : 'asc',
        }]
      }
      let target = '/subtags'
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.tagList = resp.data.data
            this.dataMeta = resp.data.meta
            this.dataCategories = resp.data.tags
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Sub Tag list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editData(data) {
      this.tagData = data
      this.isAddNewTagSidebarActive = true
    },
    resetTagData() {
      this.tagData = {}
    },
    confirmDel(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/subtag/delete', { subtagging_id: data.subtagging_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Sub Tag has been deleted.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getTagData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error deleting Sub Tag',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
